<script setup>
import { defineProps, defineEmits, useSlots } from 'vue'

const slots = useSlots()

defineProps({
	isVisible: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
	width: {
		type: Number,
		required: false,
		default: 420,
	},
})

const emit = defineEmits(['close'])

function close() {
	emit('close')
}
</script>

<template>
	<el-dialog
		id="it-dialog"
		:model-value="isVisible"
		:width="width"
		:show-close="false"
		@close="close"
	>
		<div class="flex justify-between items-center mb-3">
			<div class="flex items-center gap-3">
				<slot v-if="slots.titleIcon" name="titleIcon" />
				<span class="text-cod-gray text-18 font-medium">{{ title }}</span>
			</div>
			<ItIcon name="close" class="cursor-pointer" @click="close" />
		</div>

		<slot />
	</el-dialog>
</template>

<style lang="scss">
#it-dialog {
	padding: 20px;

	@media only screen and (max-width: 420px) {
		width: 90%;
	}

	.el-dialog__header {
		padding-bottom: 0;
	}
}
</style>
